type TOptions = {
  foretag?: boolean;
};

type TArticle = {
  category: string;
  slug: string;
  __typename?: string;
};

type TEvent = {
  slug: string;
  __typename?: string;
};

//Expand this as a union when more types needs to be added
type DataUnion = TArticle | TEvent;

const urlResolver = (data: DataUnion, options?: TOptions) => {
  if (!data.__typename) {
    throw new Error("Typename needed to verify url type");
  }

  const url = options?.foretag ? "/foretag" : "";

  if ("category" in data && "slug" in data && data.__typename === "Article") {
    return `${url}/artiklar/${data.category}/${data.slug}`;
  }

  if ("slug" in data && data.__typename === "Event") {
    return `${url}/event/${data.slug}`;
  }

  throw new Error("__typename is not set");
};

export default urlResolver;
